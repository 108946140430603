@import '../utils/mediaQueries';

.collapse-headline {
  > div:first-child h2 {
    @extend h2;
  }
  > div > svg {
    color: $primary;
  }

  .collapse-headline__separator {
    @extend .camper-item__header__separator;
    margin-bottom: 0 !important;
  }
}

.traveller {
  &__go-back {
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;

    button.btn {
      height: 38px;
      max-width: 100px;
      width: 100%;
    }
  }
}

.iso__imageProgressBar__stepperItem {
  &::before,
  &::after {
    border-bottom-width: 9px;
  }
}

.camper-progress {
  position: relative;

  &__bar {
    height: 9px;
    background-color: $base-3;
    border-radius: 6px;
    position: relative;

    > span {
      position: absolute;
      height: 100%;
      width: 0;
      top: 0;
      left: 0;
      border-radius: 6px;
      background-color: $primary;
      transition: width 0.6s ease-in-out;
    }
  }

  &__item {
    display: flex;
    justify-content: center;
    font-weight: $font-weight-bold;
    font-size: 14px;
    padding: 0 5px;
    margin-bottom: 0.65rem;
    width: 100%;

    @include small {
      width: initial;
      margin-bottom: 0.2rem;
    }

    &__list {
      display: flex;
      margin-bottom: 0.65rem;
      flex-direction: column;

      @include small {
        flex-direction: row;
      }
    }

    > svg {
      margin-right: 6px;
      position: relative;
      top: 2px;
      color: $primary;
    }

    @include medium {
      font-size: 16px;

      > svg {
        margin-right: 8px;
        top: 1px;
      }
    }

    &--active {
      color: $primary;
      font-weight: $font-weight-bold;

      > svg {
        color: $primary;
      }
    }

    &__clickable:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

.progress-wrapper {
  overflow: hidden;
  margin: 2.5rem 0 2rem;
}

.checkout__traveller {
  &__back {
    @extend .btn-secondary--stroked;
  }

  &__warning {
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    > svg {
      font-size: 3rem;
      color: $tertiary;
      margin-right: 20px;
    }
  }
  b {
    font-weight: 700;
  }
}

.camper-item__summary {
  .camper-item__header__separator {
    display: none;
  }

  > div > div > div:nth-child(2) {
    padding: 1rem;
  }

  .camper-item__supplier {
    background-color: transparent;
    padding-left: 0;
  }

  .camper-item__summary__info {
    display: none;
  }

  .rental-details__inner {
    .rental-details__headline--main {
      color: $base-2;
      font-weight: $font-weight-bold;
      margin-bottom: 8px;
    }

    > .rental-details__headline--main:first-child {
      display: none;
    }

    .rental-details__data {
      svg {
        position: relative;
        top: 2px;
        color: $primary;
      }
    }
  }

  &.two-column-layout .camper-item__main {
    @include large {
      justify-content: flex-start;
    }
  }
}

.camper-item {
  &__header {
    h3 {
      font-weight: $font-weight-bold;
    }
  }

  &__rates__list__name {
    font-weight: $font-weight-bold;
  }
}

.form__form-builder__container {
  background-color: $white;
  margin-top: 0;
  padding-top: 0;
  padding-left: 0;
  padding-bottom: 50px;

  @include large {
    padding-right: 0;
  }

  > div:first-child {
    margin-bottom: 2px !important;

    svg {
      color: $primary;
      font-size: 22px;
      position: relative;
      top: -2px;
      transition: color 0.25s ease-in-out;

      &:hover {
        color: $secondary;
      }
    }
  }

  h3 {
    font-size: 22px;
    font-weight: $font-weight-bold;
    color: $primary;
  }

  .element-renderer {
    margin-bottom: 0 !important;

    &__icon--closed {
      color: $gray-500;
    }

    &__icon--open {
      color: $primary;
    }
  }
}

.form__datepicker {
  &__icon {
    color: $secondary;
    right: 11px;

    &__container {
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      width: 38px;
      background-color: $base-3;
      border: 1px solid $base-2;
      border-left: 0;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }

  .react-datepicker-popper[data-placement^='bottom'] {
    .react-datepicker__triangle,
    .react-datepicker__triangle::before {
      border-bottom-color: $primary;
    }
  }

  .react-datepicker {
    &__header {
      background-color: $primary;
    }

    &__navigation {
      &--next {
        border-left-color: $white;

        &:hover {
          border-left-color: $base-4;
        }
      }

      &--previous {
        border-right-color: $white;

        &:hover {
          border-right-color: $base-4;
        }
      }
    }

    &__day--keyboard-selected {
      background-color: $primary;

      &:hover {
        background-color: $secondary;
      }
    }

    &__day-name,
    &__current-month {
      color: $white;
    }

    &__month-select,
    &__year-select {
      height: 32px;
      border-radius: 4px;
      border: 0;
      margin: 5px 0 3px;
    }
  }
}

.traveller-builder {
  &__remove-modal {
    .btn.btn-primary {
      height: 38px;
    }
  }
}

.modal.show .modal-dialog.traveller-builder__remove-modal {
  left: 50%;
  transform: translate(-50%, 3rem) translateX(-8px);
}
