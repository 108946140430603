.iso__serviceList__filterContainer {
  border-color: $white;
  box-shadow: 0 0 8px $gray-400;
  border-radius: 6px;
  overflow: hidden;
  margin: 0 6px 25px 0;

  .card {
    border-radius: 6px;
  }

  .card-header {
    background-color: $white;
    border-bottom-color: $white;

    &:first-child {
      border-radius: 6px;
    }
  }

  .card-body {
    padding-top: 0.5rem;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }

  .iso__filter__discardContainer {
    justify-content: center;

    .iso__filter__resetButton {
      @extend .btn;
      @extend .btn-secondary;
      @extend .btn-secondary--stroked;
    }
  }

  .iso__baseLayoutFilter__headlineContent {
    margin-bottom: 10px !important;
    margin-top: 10px;
    box-shadow: none;
    border-bottom: 1px solid $base-3;
  }

  .iso__baseLayoutFilter__headline {
    font-weight: $font-weight-bold;
  }

  .iso__baseLayoutFilter .form-check {
    margin-bottom: 16px;
  }

  .form-check-label {
    font-size: 14px;
    font-weight: $font-weight-bold;
  }

  .iso__filterRange__values {
    margin-left: 0 !important;
    margin-bottom: 6px;

    > div {
      justify-content: center;
      font-weight: $font-weight-bold;
      font-size: 15px;
    }
  }

  .iso__filterRange__thumb {
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.8);
    border-radius: 4px;
    width: 1rem;

    &::after {
      content: none;
    }
  }

  .iso__filterRange__track {
    background: $base-3;
    height: 4px;
    top: 11px;
  }
}
