.show-favorites {
  background-color: $primary-hover;
  cursor: pointer;
  padding-left: 0;
  padding-right: 0;
  transform: translateX(100%) translateX(-20px);
  transition: transform 0.3s ease-in-out, background-color 0.3s ease-in;
  height: 60px;
  border-radius: 4px 0 0 4px;

  > div {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
  }

  svg {
    transform: translateX(0);
    transition: transform 0.3s ease-in-out, font-size 0.3s ease-in, left 0.3s ease-in-out;
    font-size: 0.85rem;
    left: 3px;
    position: absolute;
    top: 0;
  }

  &.show-favorites--active,
  &:hover {
    background-color: $primary-hover;
    transform: translateX(0);

    svg {
      transform: translateX(-50%);
      font-size: 1.25rem;
      left: 50%;
    }

    .show-favorites__count + div {
      transform: translateX(0);
    }
  }

  &.show-favorites--active {
    background-color: $primary;

    &:hover {
      background-color: $primary-hover;
    }
  }

  &.show-favorites--inactive {
    cursor: default;
    background-color: $primary-hover;

    &:hover {
      transform: translateX(100%) translateX(-20px);
    }

    svg {
      transform: translateX(0);
      font-size: 0.85rem;
      left: 3px;
    }

    .show-favorites__count + div {
      transform: translateX(0);
    }
  }

  &--mobile {
    position: relative;
    transform: translateX(0);
    display: inline-block;
    height: initial;
    padding: 10px 0 0;
    z-index: 1;

    &.show-favorites:hover {
      transform: translateX(0);
    }

    &.show-favorites.show-favorites--active,
    &.show-favorites.show-favorites--inactive {
      > div {
        flex-direction: row;
      }

      svg {
        position: relative;
        transform: initial;
        font-size: 1.25rem;
        left: 8px;
      }

      .show-favorites__count {
        transform: initial;
        left: 23px;

        + div {
          position: relative;
          top: -5px;
          margin-right: 10px;
        }
      }
    }
  }

  .show-favorites__count {
    background-color: $white;
    color: $base-1;
    padding-top: 2px;
    font-weight: $font-weight-bold;
    left: calc(50% + 5px);
    right: initial;
    height: 17px;
    width: 17px;
    font-size: 9px;
    transform: translateX(0);

    + div {
      transition: transform 0.3s ease-in-out;
      margin: 0 25px;
      transform: translateX(0);
    }
  }
}

.with-favorites-portal-container {
  text-align: right;
}
