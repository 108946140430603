.picture-gallery {
  &__small__group,
  &__small__single,
  &__large {
    border-radius: 6px;
    overflow: hidden;
    box-shadow: 0 0 6px -4px rgba(0, 0, 0, 0.8);
  }

  &__buttons {
    display: flex;
    top: 10px;

    @include medium {
      top: initial;
      bottom: 10px;
    }
  }
}

.image-gallery-content .image-gallery-slide .image-gallery-image {
  max-height: 100%;
  height: 100%;
  object-fit: cover;

  &.image-gallery-image--portrait {
    width: auto;
  }
}
