.payone-cc {
  padding-top: 15px;

  @include small {
    padding-left: 50px;
  }

  .form-group {
    width: 100%;
  }
}

.payment_selection {
  .card,
  .custom-checkbox,
  .custom-control-label {
    width: 100%;
  }
}

.payone-cc-icon {
  width: auto;
  height: 30px;
  opacity: 0.5;

  &--clickable:hover {
    cursor: pointer;
  }

  &__container {
    padding: 3px 6px;
    border-radius: 6px;

    &--selected {
      box-shadow: 0 0 8px $gray-400;

      img {
        opacity: 1;
      }
    }
  }
}

.payone-input-mock {
  border-radius: 4px;
  border: 1px solid $base-2;
  height: 60px;
  display: flex;
  align-self: center;
  width: 100%;
  padding: 0.375rem 0.75rem;

  &.invalid {
    border: 1px solid red;
  }

  &:focus-visible {
    outline: none;
    box-shadow: none;
  }

  > span {
    display: flex;
    align-items: center;
  }

  iframe {
    height: 24px;
    width: 100%;
    vertical-align: middle;
    border: none;
  }
}

.payone {
  &__check {
    max-width: 160px;
    width: 100%;
  }
}
