@import '../utils/mediaQueries';

.leave-modal {
  box-shadow: 0 0 8px $gray-700;
  border: 0;
  border-radius: 6px;

  .modal.show &.modal-dialog {
    left: 50%;
    transform: translateX(-50%);
    margin: 1rem 1rem 0 0;
  }

  @include medium {
    max-width: 650px !important;
  }

  .modal-content {
    padding: 1.5rem;

    @include medium {
      max-width: 650px !important;
    }
  }

  h2 {
    font-size: 21px;
    font-weight: $font-weight-bold;
    margin-bottom: 5px;
  }

  &__inner {
    display: flex;
    flex-direction: column;
    margin-top: 20px;

    @include medium {
      flex-direction: row;
      align-items: flex-end;
      justify-content: space-between;
    }

    button {
      height: 38px !important;
    }
  }

  &__phone {
    font-size: 24px;
    font-weight: $font-weight-bold;
    display: flex;
    align-items: flex-start;
    margin: 10px 0 25px;

    @include medium {
      margin: 10px 0 0;
    }

    a {
      color: inherit;
      transition: color 0.25s ease-in-out;

      &:hover {
        text-decoration: none;
        color: var(--primary);
      }

      + div {
        margin-top: 6px;
      }
    }

    > div > div {
      font-weight: 300;
      font-size: 0.85rem;
    }

    svg {
      margin-right: 15px;
      position: relative;
      top: 0;
      color: $primary;
    }
  }

  button {
    border-radius: 3px;
    max-width: 360px;
    width: 100%;
    align-self: center;

    @include medium {
      align-self: flex-end;
      max-width: 150px;
      position: relative;
    }
  }
}
