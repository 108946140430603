.capture-data {
  .react-time-picker {
    display: block;
    height: 60px;

    &__wrapper {
      border: 0;
    }

    &--disabled {
      background-color: $gray-200;
    }
  }

  input.form-control:disabled:hover {
    background-color: $gray-200;
  }

  .react-datepicker__input-container input {
    min-height: 60px;
    border-color: $base-2;

    &:hover,
    &:focus,
    &:active {
      box-shadow: none;
    }

    &:focus {
      border-color: $primary;
    }

    &:disabled {
      background-color: $gray-200;
    }
  }

  .form__error {
    top: initial;
    bottom: -18px;
  }

  &__checkbox-column {
    align-items: center;
    display: flex;
    position: relative;
    top: 15px;
  }

  &__calendar {
    position: relative;

    input:hover {
      background-color: $base-4;
    }

    &__icon {
      position: absolute;
      right: 20px;
      display: inline-block;
      top: 50%;
      transform: translateY(-50%);
      font-size: 1.2rem;
      cursor: text;

      &:hover {
        color: $primary;
      }
    }
  }
}
