@import '../utils/mediaQueries';
@import '../Vars';

.camper-extras {
  &__item {
    margin-top: 0;

    &__label {
      position: relative;

      @include small {
        align-items: flex-start;
        top: initial;
      }

      &__popover {
        ul {
          padding-left: 25px;
        }
      }
    }

    &__total--price {
      text-align: right;
      font-weight: 700;

      .camper-price-info & {
        flex: inherit;
        display: flex;
        flex-wrap: nowrap;
        white-space: nowrap;
        justify-content: flex-end;
        font-weight: 500;

        @include break(1280px) {
          flex: inherit;
        }
      }

      .iso__price__value {
        white-space: nowrap;
      }
    }

    &__total--label {
      word-break: break-word;
      margin-right: 10px;

      .camper-price-info & {
        flex: inherit;

        @include break(1280px) {
          flex: inherit;
        }
      }
    }

    &__sub-label {
      .camper-price-info & {
        font-size: 10px;
      }
    }

    > div {
      @include xlarge {
        width: 100%;
      }
    }

    &__single-price {
      white-space: nowrap;
      font-size: 0.9rem;

      &--mobile {
        @include xlarge {
          width: 0 !important;
        }
      }
    }

    &__on-request__marker {
      color: $tertiary;
      margin: 0 !important;
    }

    &--selected {
      color: $primary;
    }

    &__validation,
    &__external {
      align-items: flex-start !important;

      > div {
        align-items: flex-start !important;
      }

      &__text {
        ul {
          padding-left: 30px;
        }
      }
    }

    &__validation--error {
      color: $red;

      a {
        color: $red;
      }

      .custom-control-input[type='radio'] + .custom-control-label::before,
      .custom-control-input[type='checkbox'] + .custom-control-label::before {
        border-color: $red;
      }
    }

    &__popover {
      box-shadow: 0 2px 8px -3px $gray-500;

      @include medium {
        min-width: 500px;
      }
    }
  }

  &__single {
    .custom-checkbox.custom-control {
      margin-right: 25px;
    }
  }

  &__multi {
    position: relative;
    font-weight: 500;

    > svg {
      position: relative;
      top: -2px;
    }
  }

  &__modal-body {
    h4 {
      font-size: 18px;
    }
  }

  .camper-extras__item__on-request .camper-item__popover__icon {
    font-size: 16px;
    margin-right: 5px;
  }

  .camper-extras__icon__headline {
    font-size: 22px;
  }

  .camper-extras__item:not(:first-child) {
    margin-top: 10px;
  }
}

.camper__feature-headline {
  font-weight: 700;
  margin-right: 0.5rem;

  &__container {
    flex-wrap: wrap;
    align-items: flex-end;

    > div:last-child {
      position: relative;
      top: -3px;

      &.camper__feature-headline__image {
        top: 4px;
      }
    }
  }

  &__link {
    text-decoration: underline;
    color: $primary;
    cursor: pointer;
    font-weight: $font-weight-bold;
    margin-left: 0;
    margin-right: 1.5rem;
    font-size: 1.02rem;
    position: relative;
    top: -1px;

    &:hover {
      color: $secondary;
    }
  }
}

h3.camper__feature-headline {
  margin-bottom: 0 !important;
}

.camper-details__price {
  width: calc(100% - 3px);

  > div {
    padding: 12px !important;
  }
}

.camper-price-info {
  > .row {
    margin-top: 12px;
  }

  .row {
    padding: 0 0.5rem;
    justify-content: space-between;
    position: relative;
    flex-wrap: nowrap;
  }

  .font-weight-bold {
    font-weight: 700 !important;
  }

  .camper-item__status svg {
    margin-left: 0;
  }

  .camper-price-info--btn {
    color: $secondary;
    background-color: transparent;
    font-size: 14px !important;

    svg {
      color: $secondary !important;
    }

    &:focus,
    &:active,
    &:hover {
      border: 2px solid $primary !important;
      color: $primary !important;
      background-color: transparent !important;

      svg {
        color: $primary !important;
      }
    }
  }
  .camper-extras__discount {
    &--label {
      &--green {
        flex: inherit;
        position: relative;
        white-space: nowrap;
        bottom: -2px;
        color: #1db377 !important;
        @include break(1280px) {
          flex: inherit;
        }

        > div {
          font-size: 14px !important;
          font-weight: 400 !important;
        }
      }
      &--red {
        flex: inherit;
        position: relative;
        white-space: nowrap;
        bottom: -2px;
        color: orangered !important;
        @include break(1280px) {
          flex: inherit;
        }

        > div {
          font-size: 14px !important;
          font-weight: 400 !important;
        }
      }
    }

    &--value {
      flex: inherit;
      color: #1db377 !important;
      @include break(1280px) {
        flex: inherit;
      }
    }
    &__input {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      &--input {
        height: 30px;
        min-width: 50px;
        flex: 1;
        padding: 8px;
        border: 1px solid #ccc;
        border-radius: 4px;
        font-size: 12px;
      }

      &--btn {
        height: 30px !important;
        margin: 0 0 0 10px !important;
        padding: 8px 16px;
        font-size: 14px !important;
        min-width: 90px !important;
      }
    }
  }
  .camper-extras__item__final-price {
    align-items: center;

    .camper-extras__item__total--label {
      flex: inherit;
      position: relative;
      white-space: nowrap;
      bottom: -2px;

      @include break(1280px) {
        flex: inherit;
      }

      > div {
        font-size: 14px !important;
        font-weight: 400 !important;
      }
    }

    .camper-extras__item__total--price {
      font-size: 21px;
      font-weight: 700;
      flex: inherit;

      @include break(1280px) {
        flex: inherit;
      }
    }
  }

  .camper-extras__item__total-container::before {
    display: inline-block;
    content: '';
    position: absolute;
    height: 2px;
    width: calc(100% - 1rem);
    background-color: $base-3;
    left: 0.5rem;
    margin-bottom: 14px;
    margin-top: -3px;
    top: -13px;
  }

  .camper-extras__item__counter {
    font-weight: 700;
  }

  .camper-item__header__separator {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }
}

.camper-extras {
  .camper-item__header__separator {
    margin-bottom: 15px !important;
  }
}

.camper-specials {
  &__offer {
    margin-bottom: 15px;
    .form-check-input {
      margin-top: 2px;
    }
    .custom-control-label {
      margin-bottom: 0;
      padding-left: 10px;
      padding-top: 4px;
    }
  }
}

.camper-item__rates__list--disabled {
  .camper-item__rates__list__name,
  .camper-item__rates__list__price {
    color: $base-3;
  }
}

.camper-details
  .package-selection__rate-container
  .camper-item__rates__desktop.camper-item__rates__list--disabled
  .camper-item__rates__list__price {
  color: $base-3;
}

.camper-details
  .package-selection__rate-container
  .camper-item__rates__desktop.camper-item__rates__list--disabled
  .camper-item__rates__desktop__selected {
  color: $base-3;
}
