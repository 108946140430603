@import '../Vars';
@import '../utils/mediaQueries';

.camper-details {
  .camper-item__header {
    h3 {
      @extend h2;
    }

    > div:last-child {
      display: none;
    }

    &:first-of-type {
      + div + div {
        margin-bottom: 20px;

        @include medium {
          position: absolute;
          right: 22px;
          top: 20px;
        }

        .camper-item__supplier {
          display: none;
        }
      }
    }
  }

  .package-selection {
    .package-selection__rates .camper-item__rates__dropdown {
      display: flex;
      flex-direction: row;
    }
  }

  .camper__feature-headline {
    @extend h2;

    + svg {
      font-size: 20px;
    }
  }

  .camper-item__features {
    .camper-item__feature {
      margin-bottom: 30px !important;
      margin-right: 24px;

      @media (max-width: 575px) {
        > svg {
          margin-right: 0.5rem !important;
        }
      }

      > span {
        font-size: 16px;
        font-weight: $font-weight-bold;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        border: none;
      }
    }
  }

  .button-separator {
    margin-top: 10px;
    max-width: initial;

    &::before {
      background-color: $primary;
    }

    @include large {
      max-width: initial;
    }

    button {
      @extend .btn-primary--stroked;
      height: 38px;
    }
  }

  .rental-details {
    &__headline {
      margin-bottom: 0.4rem !important;
      color: $base-1;
      font-weight: $font-weight-bold;

      &--main {
        color: $base-2;
        font-weight: $font-weight-bold;
        margin-bottom: 8px;

        + div {
          margin-bottom: 25px !important;
        }

        &:first-child + div {
          font-weight: $font-weight-bold;
        }
      }
    }

    &__date svg {
      font-size: 18px;
      position: relative;
      top: 0;
      color: $primary;
    }
  }
}

.camper-item__header__separator {
  display: block;
  height: 5px;
  margin-top: 12px !important;
  margin-bottom: 25px !important;
  margin-left: -1rem;
  margin-right: -1rem;
  background-color: $base-3;
  width: calc(100% + 2rem);

  &::before {
    content: none;
  }
}

.camper-details__price {
  &-info--container {
    flex: 1 1 auto;
    display: flex;
    align-items: center;
  }

  .camper-item__popover {
    padding: 1rem;
  }
}

.camper-item__popover {
  overflow: auto !important;

  &--click {
    cursor: pointer;
  }

  &__specials {
    .arrow {
      display: none;
    }

    .camper-item__specials__specials-content {
      box-shadow: 0 4px 8px $gray-400;
    }

    > .popover {
      max-width: initial;
    }
  }
}

.camper-item__status:hover {
  .camper-on-request-info .camper-item__popover__icon path {
    color: lighten($tertiary, 10%);
  }
}

.camper-item__status .camper-on-request-info .camper-item__popover__icon:hover {
  path {
    color: lighten($tertiary, 10%);
  }
}

.camper-extras__item__final-price {
  flex-direction: column;
  padding-right: 10px;

  @include small {
    flex-direction: row;
  }
}

.fixed-scroll__container {
  padding-bottom: 4.55rem !important;

  .checkout__traveller &,
  .checkout__customer & {
    padding-bottom: 1.55rem !important;
  }
}
