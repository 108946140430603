.camper__top-content {
  display: flex;

  > button:first-child {
    @extend .btn-secondary--stroked;
    margin-right: 15px;

    > svg {
      margin-right: 6px;
    }
  }
}

.camper__breadcrumb {
  display: flex;
  position: relative;
  top: 11px;

  > div {
    cursor: pointer;

    &:first-child {
      margin-right: 4px;

      svg {
        font-size: 22px;
        position: relative;
        top: -3px;
      }
    }

    &:nth-child(2) {
      margin-right: 4px;
    }

    &:last-child > span {
      font-weight: $font-weight-bold;
    }
  }
}
