@import '../utils/mediaQueries';

.checkout__customer {
  .payment_selection {
    .custom-control {
      input + label {
        padding-left: 25px;
        padding-top: 7px;
      }
    }
  }

  .terms-and-conditions {
    h3 {
      font-size: 22px;
      font-weight: $font-weight-bold;
      margin-top: 10px;
    }

    .custom-control {
      input + label {
        padding-left: 12px;
        padding-top: 7px;
      }
    }

    > .row > div:first-child svg {
      color: $tertiary;
    }

    > .row > .col-md-11 > .row > .col-12 {
      margin-bottom: 0.25rem !important;
      margin-top: 0 !important;
    }

    &__icon {
      min-width: 28px;
    }
  }

  .checkout-insurance {
    .col-md-1 svg {
      color: $tertiary;
      scale: 3;
      margin: 1em;
    }

    .custom-control {
      input + label {
        padding-left: 12px;
        padding-top: 7px;
      }
    }
  }

  .checkout-address-form form {
    padding-bottom: 1rem;
  }
}

.checkout-address-form {
  .element-renderer {
    margin-bottom: 0 !important;

    &:first-child {
      .select__control {
        .select__placeholder,
        .select__single-value {
          font-weight: $font-weight-bold;
          color: $primary;
        }
      }
    }

    &__icon--closed {
      color: $gray-500;
    }

    &__icon--open {
      color: $primary;
    }
  }
}

.form__error__top,
.terms-and-conditions--error {
  a {
    color: $red;
  }

  .custom-control-input[type='radio'] + .custom-control-label,
  .custom-control-input[type='checkbox'] + .custom-control-label {
    &::before {
      border-color: $red;
    }
  }

  .custom-control-input[type='radio'] + .custom-control-label::after {
    background-color: $red;
  }
}

.payment {
  &__sub-label {
    font-size: 0.9rem;
  }

  &__direct-debit-checked {
    padding: 15px 0;

    @include small {
      padding: 15px 0 15px 50px;
    }

    &__content {
      padding-top: 15px;

      ul {
        padding-left: 32px;
        margin-left: 0;
      }
    }
  }
}
