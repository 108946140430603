.checkout__customer {
  .payment--section {
    .invalid {
      .card,
      input {
        border: 1px solid red;
      }
    }
  }

  @include large {
    .camper-item__summary .camper-item__left {
      min-height: 225px;
    }
  }
}
