.information-layer__modal {
  &.modal-dialog {
    width: calc(100% - 1rem);
  }

  .information-layer__slider {
    height: 60px;

    @include medium {
      margin-top: 1.5rem !important;
    }

    .ibe-tabs__left,
    .ibe-tabs__right {
      height: 60px;
      width: 25px;
    }

    .ibe-tabs__left {
      background: linear-gradient(to right, $primary 15%, transparent);

      &::after {
        left: 6px;
        border-color: $tertiary;
      }
    }

    .ibe-tabs__right {
      background: linear-gradient(to left, $primary 15%, transparent);

      &::after {
        right: 6px;
        border-color: $tertiary;
      }
    }

    &__container {
      height: 80px;
    }

    .nav-item {
      overflow: hidden;
      padding-left: 1px;
      padding-right: 1px;

      &:first-child {
        border-radius: 4px 0 0 4px;
        padding-left: 0;
      }

      &:last-child {
        border-radius: 0 4px 4px 0;
        padding-right: 0;
      }

      &:not(:first-child) {
      }
    }

    .nav-tabs .nav-link {
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $base-3;
      font-weight: $font-weight-bold;
      color: $base-1;
      border-bottom: 7px solid $base-3;

      &.active {
        border-bottom: 7px solid $primary;
        color: $primary;
      }

      &:hover {
        border-bottom-color: $primary;
      }
    }
  }

  .image-gallery {
    margin: 25px auto 0;
    max-width: 992px;

    &-icon {
      color: $primary;
      background-color: white;
      padding: 0px;
      margin: 0 10px 0 10px;
      border-radius: 10px;
    }

    &-slide-wrapper {
      border-radius: 6px;
      box-shadow: 0 0 8px $gray-600;
      overflow: hidden;
    }

    &-thumbnail {
      border-radius: 6px;

      &:focus,
      &.active,
      &:hover {
        border: 3px solid $primary;
      }

      &-inner {
        img {
          box-shadow: 0 0 4px 0 $gray-600;
          border-radius: 4px;
        }
      }
    }

    &-image + caption {
      position: absolute;
      right: 0;
      bottom: 0;
      text-align: center;
      background-color: $white;
      color: $gray-base;
      opacity: 0.6;
      padding: 10px;
      letter-spacing: 0;

      @include medium {
        font-size: 12px;
        max-width: 195px;
      }
    }
  }

  &__close__container {
    position: absolute;
    right: 1rem;
    top: 19px;

    svg {
      color: $primary;
      transition: color 0.25s ease-in-out;

      &:hover {
        color: $secondary;
      }
    }

    @include medium {
      margin-top: 0;
    }
  }

  .information-layer {
    &__container {
      @include medium {
        margin-top: 0;
      }
    }

    &__title {
      > div {
        > span:first-child {
          font-size: 28px !important;
          font-weight: $font-weight-bold;
        }

        > span:last-child {
          @include medium {
            display: none !important;
          }
        }
      }

      .information-layer__separator {
        height: 5px;
        width: calc(100% + 2rem);
        margin: 25px 0 0 -1rem;

        &::before {
          content: none;
        }
      }

      > div:first-child {
        margin-top: 8px;
      }

      > div:last-child {
        @include medium {
          display: block !important;
        }
      }
    }
  }

  .modal &.modal-dialog {
    width: calc(100% - 20px);
    max-width: 1280px !important;
    margin-left: 10px;
    margin-right: 10px;
    left: 50%;
    transform: translateX(-50%) translateX(-10px) !important;
    border-radius: 10px;
    overflow: hidden;

    .information-layer__container {
      max-width: 100%;
      margin-top: 0;
    }
  }

  .modal-content {
    border-radius: 6px;
  }

  .vehicle-information__supplier {
    .camper__supplier__description {
      margin-top: 1.5rem;
    }
  }

  .camper__tcs {
    > h4 {
      font-size: 18px;
      font-weight: $font-weight-bold;
      margin-bottom: 20px;
    }
  }
}

.information-layer__modal .information-layer__slider .nav-tabs .nav-link {
  height: 20px;
  font-size: 0.7rem;
  border-bottom: 4px solid $base-3;
  @include medium {
    height: 40px;
    font-size: 1rem;
    border-bottom: 6px solid $base-3;
  }
  @include large {
    height: 60px;
    font-size: 1.2rem;
    border-bottom: 7px solid $base-3;
  }

  &.active {
    border-bottom: 4px solid $primary;
    @include medium {
      border-bottom: 6px solid $primary;
    }
    @include large {
      border-bottom: 7px solid $primary;
    }
  }
}

.information-layer__modal .nav-tabs {
  margin-left: 0;
}

.information-layer__modal .nav-tabs .nav-item {
  min-width: 120px;
  @include medium {
    min-width: 160px;
  }
  @include large {
    min-width: 196px;
  }
}

.information-layer__modal .image-gallery {
  margin: 7px auto 0;
  @include large {
    margin: 25px auto 0;
  }
}

.information-layer__modal .information-layer__slider {
  height: 40px;
  @include medium {
    height: 50px;
  }
  @include large {
    height: 60px;
  }
}

.information-layer__container .camper__features > div:first-child {
  margin-top: 0;
  @include large {
    margin-top: 1.5rem;
  }
}

.information-layer__modal .information-layer__slider .ibe-tabs__left {
  background: linear-gradient(to right, white 55%, transparent);
}

.information-layer__modal .information-layer__slider .ibe-tabs__right {
  background: linear-gradient(to left, white 55%, transparent);
}

.information-layer__modal .information-layer__slider .ibe-tabs__left,
.information-layer__modal .information-layer__slider .ibe-tabs__right {
  margin-top: 1px;
  height: 23px;
  display: flex;
  @include medium {
    margin-top: 0;
    height: 39px;
  }
  @include large {
    margin-top: 0;
    height: 59px;
  }
}

.information-layer__modal .information-layer__slider .ibe-tabs__right::after,
.information-layer__modal .information-layer__slider .ibe-tabs__left::after {
  border-color: $primary;
}
