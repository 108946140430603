@import '../utils/mediaQueries';

.camper-insurance {
  &__teaser {
    h3 {
      line-height: 1.4;
    }

    p {
      line-height: 1.8;
      margin-bottom: 0;
    }

    .form-toggle {
      margin-bottom: 0;
    }
  }

  &__logo {
    width: 128px;
  }

  &__radio {
    input {
      top: 0;
    }
  }

  &__sub-header {
    > p:first-child {
      margin-bottom: 1rem !important;
    }

    > div:last-child {
      @include medium {
        margin-bottom: 15px;
      }
    }
  }

  &__box {
    .camper-insurance__radio {
      input {
        flex: 1 0 20px;
      }

      &__no-insurance {
        margin-left: 0.5rem;
        display: flex;

        > span {
          top: 0;
        }
      }
    }
  }

  &__recommended {
    background-color: $tertiary;
  }
}
