.camper-item {
  &__features {
    display: flex;
    flex-wrap: wrap;
    font-size: 0.86rem;
    list-style-type: none;
    padding-left: 0;

    .collapse-headline--open {
      h3 {
        color: $primary;
      }
    }

    &--active {
      background-color: $gray-200;
    }
  }

  &__feature {
    align-items: center;
    display: inline-flex;
    flex: 1 1 calc(50% - 24px);
    margin-bottom: 5px;

    svg {
      color: $secondary;
      font-size: 24px;
    }

    span {
      white-space: nowrap;
    }
  }
}

.information-layer__container .camper__features {
  > div {
    > div.row:not(:first-of-type) {
      margin-top: 25px;
    }
  }

  .camper-item {
    &__features {
      padding-left: 1rem;
      padding-right: 1rem;
      border-bottom: 1px solid $gray-200;

      .collapse-headline {
        width: 100%;

        > div:first-child {
          height: 54px;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        &__separator {
          display: none;
        }

        h3 {
          font-size: 18px;
          font-weight: 700;
          border-radius: 6px;
          width: 100%;
          height: 35px;
          display: flex;
          align-items: center;
          margin-bottom: 0 !important;
        }
      }
    }

    &__feature {
      font-size: 16px;

      &:first-child {
        font-weight: $font-weight-bold;
      }

      &:last-child {
        display: flex;
        justify-content: flex-end;
        text-align: right;
      }

      &__row {
        margin-bottom: 12px;
      }
    }
  }

  &__group-title {
    position: relative;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 0.5rem;
    margin-top: 1.5rem;
  }

  .vehicle-comparison__icon {
    &__positive path {
      color: $green;
    }

    &__negative path {
      color: $red;
    }
  }
}

.camper__features {
  &__container {
    box-shadow: 0 0 4px $gray-400 !important;
    margin-bottom: 0 !important;
    margin-top: 0 !important;
  }
}
