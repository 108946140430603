.vehicle-comparison__table.table.table-borderless {
  tbody {
    position: relative;
  }

  .vehicle-comparison {
    &__remove {
      background-color: $primary;
      border-radius: 6px;

      &:hover {
        background-color: $primary-hover;
      }
    }

    &__headline {
      border-bottom: 0;
    }

    &__label {
      .vehicle-comparison__col--first {
        padding-left: 12px;
      }
    }

    &__img-row {
      &:first-of-type .vehicle-comparison__col {
        padding-top: 6px;
      }
    }

    &__img img {
      position: absolute;
      top: -6px;
      height: 100%;
      width: calc(100% + 12px);
      object-fit: cover;
      left: -6px;
      border-radius: 6px 6px 0 0;
    }

    &__col--first {
      min-width: 220px;
    }

    &__column-backdrop {
      position: absolute;
      top: 0;
      width: 212px;
      height: calc(100% + 6px);
      box-shadow: 0 0 8px $gray-400;
      border-radius: 6px;

      @for $i from 0 through 6 {
        &--#{$i} {
          left: calc((232px * #{$i}) + 22px);
        }
      }

      &:first-child {
        z-index: -1;
        width: 254px;
        left: -10px;
        box-shadow: none;
      }
    }

    &__status {
      &--request,
      &--available {
        font-size: 14px;
        padding: 2px 6px;
        border-radius: 4px;
        background-color: $green-light;
        color: $green;
      }

      &--request {
        background-color: $beige;
        color: $tertiary;

        .camper-on-request-info {
          .camper-item__popover__icon {
            path {
              color: $tertiary;
            }
          }
        }

        &:hover {
          .camper-on-request-info .camper-item__popover__icon path {
            color: lighten($tertiary, 10%);
          }
        }
      }
    }
  }

  th .vehicle-comparison__status--available,
  th .vehicle-comparison__status--request {
    background-color: transparent;
    color: $secondary;
    font-size: 0.95rem;
    padding: initial;
  }

  td {
    padding: 0 1rem;
    position: relative;

    .vehicle-comparison__col {
      width: 212px;
      padding: 0 6px;
      position: absolute;
      height: 100%;
      left: 50%;
      transform: translateX(-50%);
      text-align: center;

      svg {
        color: $primary;

        &.vehicle-comparison__icon {
          font-size: 1rem;

          &__positive {
            color: var(--green);
          }

          &__negative {
            color: var(--red);
          }
        }
      }

      .vehicle-comparison__img svg {
        color: $white;
      }
    }
  }
}

.vehicle-comparison {
  height: calc(100vh - 1rem);

  > .modal-content {
    height: 100%;
  }

  &__inner {
    overflow: auto !important;
  }

  &__slider {
    &__container {
      padding-top: 6px;
      padding-bottom: 10px;

      > div {
        margin-top: -12px;

        > table {
          &:first-child {
            padding-top: 6px;

            > tbody {
              overflow: hidden;
              position: absolute !important;
              z-index: 1;
              height: 260px;
              padding-top: 4px;
              background-color: $white;
            }

            &.vehicle-comparison__table--scrolled > tbody {
              box-shadow: 0 4px 5px -3px $gray-400;
            }

            .vehicle-comparison__column-backdrop {
              border-bottom-left-radius: 0;
              border-bottom-right-radius: 0;
              background-color: $white;
              top: 4px !important;
            }
          }

          &:last-child {
            padding-bottom: 10px;
            overflow: hidden;
            padding-top: 255px;

            .vehicle-comparison__column-backdrop {
              border-top-left-radius: 0 !important;
              border-top-right-radius: 0 !important;
            }

            tr:first-of-type > td:last-child {
              height: auto !important;
            }
          }
        }
      }
    }

    .ibe-tabs__button {
      svg {
        color: $primary;
      }

      &:hover svg {
        color: $secondary;
      }
    }
  }

  &__close {
    color: $primary;

    &:hover {
      color: $secondary;
    }
  }

  &.modal-dialog {
    margin: 0.5rem auto !important;
    padding: 0 0.5rem;
  }
}

.vehicle-information {
  &__gallery-info {
    max-width: 992px;
    margin: 15px auto 0;
  }
}

.rental-conditions {
  padding-top: 30px;

  &__headline__sub,
  &__headline {
    margin-top: 40px;
  }
}
