.checkout {
  &__confirmation {
    .back-home-container {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  &__traveller {
    .form__form-builder__container:first-child {
      margin-top: 0;
    }

    form {
      input[type='date'] {
        position: relative;
        padding-top: 17px;
        padding-bottom: 17px;

        &::-webkit-date-and-time-value {
          text-align: left;
        }

        @include medium {
          padding-top: initial;
          padding-bottom: initial;
        }
      }
    }

    .form__add {
      height: initial;
    }

    @include large {
      .camper-item__summary .camper-item__left {
        min-height: 225px;
      }
    }

    &__button-container {
      display: flex;
      justify-content: space-between;
      flex-direction: column;

      @include small {
        flex-direction: row;
      }
    }

    &__submit.btn.btn-primary {
      width: 100%;
      font-size: 16px;
      font-weight: 400;

      @include small {
        margin-left: 6px;
        max-width: 330px;
      }
    }

    &__submit.btn.btn-secondary {
      width: 100%;
      height: 60px;
      margin-right: 6px;
      order: 2;

      @include small {
        order: 0;
        max-width: 200px;
      }
    }

    .camper-item__header__separator.lower-separator {
      margin-top: 4rem !important;
    }
    .booking-number {
      color: var(--primary);
    }

    &__traveller-note {
      font-size: 0.9rem;
      margin: 5px 0 0;
    }
  }

  &__confirmation {
    .booking-number {
      color: var(--primary);
    }
  }
}
