@import '../utils/mediaQueries';
@import '../Vars';

.camper-details {
  .package-selection {
    &__rate-container {
      > div {
        display: flex;
        width: 100%;
        height: 100%;
      }

      .camper-item__rates__desktop {
        background-color: $white;

        .camper-item__rates__desktop__selected {
          font-weight: $font-weight-bold;
        }

        &.camper-item__rates__desktop--selected {
          background-color: $background;

          .camper-item__rates__list__name {
            color: $primary;
          }

          .camper-item__rates__desktop__selected {
            color: $primary;

            .custom-radio.custom-control .custom-control-label::after {
              background-color: $primary;
              background-image: url('data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20width=%2712%27%20height=%2712%27%20viewBox=%27-4%20-4%208%208%27%3e%3ccircle%20r=%273%27%20fill=%27%23fff%27/%3e%3c/svg%3e');
            }
          }
        }

        .custom-radio.custom-control {
          margin-right: 8px;
        }

        .camper-item__rates__list {
          &__name {
            margin-top: 0;
            font-size: 16px;
          }

          &__price {
            color: $primary;

            + .camper-item__header__separator {
              margin-bottom: 2px !important;
            }
          }
        }
      }
    }

    &__rates {
      .camper-item__rates__dropdown {
        background-color: $white;
        padding: 10px;

        &.camper-item__rates__dropdown--selected {
          background-color: $background;

          .camper-item__rates__extras {
            margin-left: -1.5rem;
          }

          .custom-radio.custom-control {
            .custom-control-label::after {
              background-color: $primary;
              background-image: url('data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20width=%2712%27%20height=%2712%27%20viewBox=%27-4%20-4%208%208%27%3e%3ccircle%20r=%273%27%20fill=%27%23fff%27/%3e%3c/svg%3e');
            }
          }
        }

        > div:last-child {
          align-items: center;
          flex-wrap: wrap;
          flex-direction: row;

          .camper-item__rates__list__name {
            margin-top: 0;
            margin-right: 10px;
          }

          .camper-item__rates__list__price {
            margin-top: 0;
            margin-right: 0;
            align-self: center;
          }
        }
      }

      .camper-item__rates__extras {
        padding: 6px;

        @include small {
          padding: initial;
        }
      }
    }

    &__selected {
      margin-bottom: 0;
    }

    .camper-item__rates__content__list li {
      @include medium {
        font-size: 14px;
        margin-bottom: 4px;
        word-break: break-all;
      }
    }

    @media (min-width: 1200px) and (max-width: 1279px) {
      #package-selection-inner.ps-xl-flex {
        display: -ms-flexbox !important;
        display: flex !important;
      }
    }

    &__selected {
      @include medium {
        flex-basis: calc(55% - 0.5rem);
      }
    }

    &__rates {
      @include medium {
        flex-basis: calc(45% - 0.5rem);
      }
    }
  }
}
.camper__checkbox.custom-radio.custom-control .custom-control-label::after,
.service-list__container .custom-radio.custom-control .custom-control-label::after {
  background-image: url('data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20width=%2712%27%20height=%2712%27%20viewBox=%27-4%20-4%208%208%27%3e%3ccircle%20r=%273%27%20fill=%27%23fff%27/%3e%3c/svg%3e') !important;
}
