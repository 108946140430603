@import '../Vars';

.two-column-layout {
  border-radius: 6px;
  border: 0;
  box-shadow: 0 0 8px $gray-400;

  > div {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
}

.camper-item {
  &__popover__container .popover {
    @media (max-width: 575px) {
      left: -75px !important;
    }
    .arrow {
      @media (max-width: 575px) {
        left: 80px !important;
      }
      left: 5px !important;
    }
  }

  .card.two-column-layout {
    border: 0;
  }

  &__left {
    margin-left: -0.5rem;
    margin-right: -0.5rem;

    .slider__awesomeSlider {
      border-radius: 6px 6px 0 0;
      overflow: hidden;

      &:hover {
        cursor: default;
      }

      @include large {
        border-radius: initial;
        overflow: initial;
      }
    }

    .awssld__controls {
      .awssld__controls__arrow-right,
      .awssld__controls__arrow-left {
        opacity: 1;

        &::after,
        &::before {
          width: 5px;
          background-color: $primary;
        }
      }
    }
  }

  &__top-equip {
    h3 {
      margin-top: 1rem !important;
    }

    ul {
      list-style-type: none;

      li {
        margin-bottom: 6px;
      }
    }
  }

  &__footer {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
    border-color: $base-3;
    align-items: flex-end;
    border-top: 1px solid $base-3;

    @include large {
      align-items: initial;
      border-top: 0;
    }

    .camper-item__rates__dropdown {
      height: initial;
    }

    &.camper-item__footer--desktop {
      .camper-item__rates__content__wrapper {
        > div {
          max-width: 240px;
        }
      }

      .camper-item__rates__dropdown {
        border: 1px solid $base-3;
        border-top: 0;
        border-bottom: 0;
        max-width: 240px;
      }

      > div:first-child {
        border-top: 1px solid $base-3;
        border-bottom-color: $base-3;
        position: relative;

        > div {
          border-color: $base-3;
        }
      }
    }

    &--desktop .camper-item__rates__dropdown--selected {
      background-color: lighten($gray-400, 10%) !important;
    }
  }

  &__rates__dropdown {
    background-color: transparent;
    border-color: $base-3;
    border-top-width: 0;
    display: flex;
    height: initial;

    > div:last-child {
      flex: 1;
      display: flex;

      @include large {
        flex-direction: column;
        border-top-width: 1px;
      }
    }

    &.camper-item__rates__dropdown--selected {
      .camper-item__rates__list__price,
      .camper-item__rates__list__name {
        color: $primary;
      }
    }
  }

  &__rates__list {
    border-color: $base-3;

    + .camper-item__rates__book.btn.btn-secondary {
      display: none;
      height: 62px;
      margin-bottom: 4px;
      border-radius: 4px 0 0 4px;

      @include small {
        display: block;
      }

      @include large {
        display: none;
      }
    }
  }

  &__rates__list__name {
    font-size: 14px;
    font-weight: $font-weight-bold;
    color: $base-1;
    margin-top: 4px;
  }

  &__rates__list__price {
    font-size: 16px;
    font-weight: $font-weight-bold;
    color: $base-1;
    margin-top: 15px;
    align-self: flex-end;
    margin-right: 6px;

    @include large {
      margin-right: 0;
    }

    .camper-item__popover__icon {
      position: relative;
      top: -3px;
    }
  }

  &__rates__content__list {
    margin-left: auto;

    li {
      display: flex;
      word-break: break-all;

      svg {
        color: $base-2;
        position: relative;
        top: 5px;
      }
    }
  }

  &__book.btn.btn-secondary {
    display: block;
    border-radius: 0;

    @include small {
      display: none;
    }

    @include large {
      margin-bottom: 6px;
      margin-right: 10px;
      height: 60px;
      max-width: 160px;
      display: block;
      border-radius: 4px;
    }
  }

  .camper-item__header {
    flex-wrap: wrap;
    > * {
      margin-bottom: 6px;
    }

    + div + div {
      flex-wrap: wrap;
    }
  }

  .camper-item__header__separator {
    display: none;
  }

  &__compare {
    margin-top: 6px;

    > span {
      @extend .text-2;
      font-weight: $font-weight-bold;
      color: $primary;
    }

    > svg {
      position: relative;
      top: 1px;
      color: $primary;

      path {
        color: $primary;
      }
    }
  }

  &__status {
    background-color: $sand;
    padding: 2px 6px;
    border-radius: 4px;
    font-weight: $font-weight-bold;
    @extend .text-1;

    &.camper-item__status--available {
      background-color: $green-light;
      color: $green;
    }
  }

  &__feature {
    path {
      color: $primary;
    }
  }

  &__supplier {
    background-color: $sand;
    padding: 3px 8px;
    border-radius: 4px;
    font-weight: $font-weight-bold;
  }

  &__popover {
    &__icon {
      font-size: 1.4rem;

      path {
        color: $primary;
      }
    }

    &__modal {
      transform: translate(0, 0) !important;
      left: 0 !important;
      height: 100% !important;
      width: 100% !important;
      margin: auto !important;
      max-height: 100% !important;
      max-width: 780px !important;
      padding: 0.75rem;
    }

    &__close {
      font-size: 2rem;
    }
  }

  &__rates__list__price svg {
    font-size: 20px;
  }

  &__modal__button.btn.btn-secondary {
    padding-bottom: 0;
    height: 30px;
  }

  &__rates__show-content--desktop.btn.btn-secondary {
    background-color: $base-4;
    border-color: $base-3;
    border-radius: 0 0 6px 6px;
    color: $primary;

    &:hover {
      background-color: $base-3;
    }

    &:active,
    &:focus {
      background-color: $base-4;
      border-color: $base-3;
    }
  }

  &__rates__show-content {
    button.btn.btn-primary {
      height: initial;
      border-radius: 0 4px 4px 0;
      font-size: 14px;
    }
  }

  &__rates__modal {
    height: calc(100vh - 1rem);
    border-radius: 4px;
    overflow: hidden !important;
    margin: 0.5rem !important;
    left: 50%;
    transform: translateX(-50%) !important;
    max-width: 650px !important;

    > div {
      height: 100%;
    }

    &__inner {
      height: 100%;
      overflow: auto;
    }

    &__close {
      color: $primary;
    }
  }

  &__specials {
    color: $white;
    font-weight: 400;
    border-radius: 6px 6px 0 0;
    font-size: 14px;
    margin-bottom: -6px;
    height: 36px;
    padding: 0 0 6px;

    svg,
    path {
      color: $white;
      font-size: 20px;
      position: relative;
      top: 2px;
    }

    &--top {
      background-color: $primary;
      font-weight: 400;

      &:hover {
        background-color: $primary-hover;
        color: $white;
      }

      &.camper-item__specials--half {
        border-radius: 6px 0 0 0;
      }
    }

    &--special {
      background-color: $tertiary;

      &:hover {
        background-color: lighten($tertiary, 8%);
        color: $white;
      }

      &.camper-item__specials--half {
        border-radius: 0 6px 0 0;
      }
    }
  }

  &__rates__dropdown__options .camper-item__rates__dropdown {
    border-color: $base-3;

    &:hover {
      background-color: $base-3;

      .camper-item__rates__list__price span {
        color: $base-1;
      }
    }
  }

  &__rates__dropdown__options .camper-item__rates__dropdown--selected {
    background-color: $gray-400;

    &:hover {
      .camper-item__rates__list__price span {
        color: $primary;
      }
    }
  }

  &__specials {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: initial;

    > svg {
      top: 0;
    }

    &__offer__headline {
      background-color: $gray-400;
    }

    &__top-content.camper-item__popover {
      overflow-y: initial;
      min-width: initial;

      div[role='button'] {
        height: 15px;
        width: 15px;
        position: absolute;
        right: 6px;
        top: 6px;
      }

      ul {
        list-style-position: inside;
      }
    }
  }

  &__popover__specials.camper-item__popover__container .Popover-tipShape {
    fill: $white;
  }

  &__popover__specials.camper-item__popover__container.Popover-below .Popover-tipShape {
    fill: $primary;
  }

  &__popover__container {
    .camper-item__popover {
      box-shadow: 0 1px 6px $gray-500;
    }
  }

  .camper-item__features {
    margin-left: 0;

    > li {
      margin-right: 24px;

      > svg {
        margin-right: 0.5rem !important;
      }
    }
  }
}

.search-collapsible {
  align-items: flex-end;

  button {
    background-color: var(--secondary);
    height: 32px;
    padding: 0 10px;
    color: var(--white);

    &:hover {
      background-color: var(--secondary);
    }
  }
}

.camper-price-info {
  font-size: 12px;
  margin-top: 1.4rem;

  .camper-item__header__separator {
    margin-left: -10px;
    margin-right: -10px;
    width: calc(100% + 20px);
  }

  .camper-details__price & .camper-item__header__separator {
    margin-left: -1rem;
    margin-right: -1rem;
    width: calc(100% + 2rem);
  }

  button.camper-price-info--btn {
    height: initial;

    &:hover {
      border-width: 2px;
    }

    > svg {
      color: $white;
    }
  }
}

.camper-item__rates__content__item:nth-child(2n + 1) {
  border-right: none;
  max-width: 100%;
}

.camper-item__header__separator {
  margin: 0;
  &::before {
    background-color: $gray-light;
  }
}

.camper-item__status {
  display: block;
  .camper-item__popover__icon path {
    color: $orange;
  }
}

.camper-item__status:hover {
  .camper-item__popover__icon path {
    color: lighten($tertiary, 10%);
  }
}

.camper__results {
  font-weight: $font-weight-bold;
  margin-top: 8px;
  margin-bottom: 1.5rem;

  > span:first-child {
    border-radius: 2px;
    background-color: $base-3;
    color: $base-1;
  }
}

.iso__filterRange__thumb {
  z-index: 0 !important;
}
