.camper__supplier {
  &__map {
    margin: 0 -1rem -1rem;
    position: relative;
    min-height: 340px;
  }

  &__headline {
    font-weight: $font-weight-bold;
    text-decoration: underline;
    margin-bottom: 6px;
  }

  &__description {
    font-weight: $font-weight-bold;
    margin-bottom: 10px;
    font-size: 18px;
  }

  &__address {
    display: flex;
    flex-direction: column;
    line-height: 24px;

    @include medium {
      flex-direction: row;
      justify-content: space-between;
    }

    &__contact {
      margin-bottom: 15px;

      &__headline {
        font-weight: $font-weight-bold;
      }
    }

    &__station {
      font-weight: $font-weight-bold;
    }

    &__data {
      margin-bottom: 15px;
    }
  }

  &__contact__headline {
    font-weight: $font-weight-bold;
  }
}
