.camper__header {
  background-color: $white;
  height: 80px;
  margin-bottom: 25px;
  box-shadow: 0 3px 6px $gray-400;
  padding: 15px 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  @include small {
    padding: 15px 25px;
  }

  @include medium {
    height: 100px;
  }

  @include xlarge {
    padding: 15px 40px;
  }

  svg {
    height: 50px;
    width: 116px;

    @include medium {
      height: 64px;
      width: 150px;
    }
  }
}
